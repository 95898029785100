import React from "react";
import gradeMap from "./gradeMap";

let prereleaseGrades = []
const availableGrades = gradeMap.availableGrades.filter(grade => !(prereleaseGrades.includes(grade)));

let grades = [];
availableGrades.forEach(function(grade){
  if (!(prereleaseGrades.includes(grade))){
    grades.push({
      internal: gradeMap[grade].kebab,
      simple: gradeMap[grade].shortId,
      abbreviated: gradeMap[grade].longDisplay,
      short: gradeMap[grade].ordinalDisplay
    });
  }
});

const GRADES = new Set(grades);

const byInternalName = new Map();
const bySimpleName = new Map();
const byAbbreviatedName = new Map();
const byShortName = new Map();

GRADES.forEach(grade => {
  byInternalName.set(grade.internal, grade);
  bySimpleName.set(grade.simple, grade);
  byAbbreviatedName.set(grade.abbreviated, grade);
  byShortName.set(grade.short, grade);
});

const isSpecialSupported = (grade) => {
  let specialGrades = ["sixth-grade", "seventh-grade", "eighth-grade"];
  if(gradeMap[grade]) {
    if(specialGrades.includes(gradeMap[grade].kebab)) {
      return true;
    }
  }

  return false;
};

export default {
  GRADES,
  availableGrades,
  byInternalName,
  bySimpleName,
  byAbbreviatedName,
  byShortName,
  isSpecialSupported
};

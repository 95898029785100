import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {

  constructor(props) {
    super(props);

    const {isInsideIFrame} = this.props;

    // EDENG-2099: Add callback to document level to listen to events outside the iFrame.
    // Last necessary measure is to trigger the loading tint (and maybe prevent scrolling) for the entire page
    this.wrapperRef = React.createRef();
    if(isInsideIFrame) {
      this.detectClickOutsideIFrame = this.detectClickOutsideIFrame.bind(this);
    }
  }

  componentDidMount() {
    const {isInsideIFrame} = this.props;
    if(isInsideIFrame) {
      window.parent.document.addEventListener("mousedown", this.detectClickOutsideIFrame);
    }
  }

  componentWillUnmount() {
    const {isInsideIFrame} = this.props;
    if(isInsideIFrame) {
      window.parent.document.removeEventListener("mousedown", this.detectClickOutsideIFrame);
    }
  }

  detectClickOutsideIFrame(event) {
    const { onClose, closeWhenClickOutside } = this.props;
    if (closeWhenClickOutside && this.wrapperRef && this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)) {
      // Outside of iframe. Attempt to stop event propagation to other parts (doesn't really work...)
      event.preventDefault();
      event.stopImmediatePropagation();
      onClose();
    }
  }

  render() {
    const { hasCloseButton, closeWhenClickOutside } = this.props;

    let className = 'react-modal center-modal';
    if(this.props.open) {
      className += ' open';
    }
    if(this.props.className) {
      className += ' '+this.props.className;
    }

    let modalStyle = {};
    if(this.props.modalStyle) {
      modalStyle = this.props.modalStyle;
    }

    return (
      <div className={className} style={modalStyle} ref={this.wrapperRef}>
        <div className="bg-overlay" onClick={closeWhenClickOutside ? this.props.onClose : undefined} />
        <div className="modal-content">
          {hasCloseButton &&
            <i className="icon-cancel-light" onClick={this.props.onClose}/>
          }
          {this.props.children}
        </div>
      </div>
    );
  }
}
Modal.displayName = 'Modal';
Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  /** Callback that is fired when the close icon is clicked or the user clicks outside the modal */
  onClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  closeWhenClickOutside: PropTypes.bool,
  isInsideIFrame: PropTypes.bool,
};

Modal.defaultProps = {
  hasCloseButton: true,
  closeWhenClickOutside: true,
  isInsideIFrame: false,
};

export default Modal;

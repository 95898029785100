import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SIZES = {
    small: 'btn-sm',
    normal: '',
    large: 'btn-lg'
};
const COLORS = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    tertiary: 'btn-tertiary',
    premium: 'btn-premium',
    teal: 'btn-teal',
    destructive: 'btn-destructive',
    'destructive-secondary': 'btn-destructive btn-outline'
};

function Button (props) {
    const {
        children,
        className,
        size,
        display,
        color,
        disabled,
        loadingText,
        onClick,
        ...otherProps
    } = props;

    const [isLoading, setLoading] = useState(false);

    const classes = clsx(
      'btn',
      className,
      SIZES[size],
      COLORS[color],
      {
        'btn-block': display === 'block',
        'disabled': disabled || isLoading,
        'loading': isLoading
      }
    );

    let handleClick = null;
    if(!disabled && !isLoading && onClick) {
        handleClick = async(e) => {
            e.preventDefault();
            setLoading(true);
            await onClick(e);
            setLoading(false);
        };
    }

    return (
        <button
            className={classes}
            disabled={disabled||isLoading}
            onClick={handleClick}
            {...otherProps}
        >
            {!isLoading ? children : (
              <React.Fragment>
                {loadingText}{' '}
                <i className="icon-spin"/>
              </React.Fragment>
            )}
        </button>
    );
}

Button.displayName = 'Button';
Button.propTypes = {
    size: PropTypes.oneOf(Object.keys(SIZES)),
    display: PropTypes.oneOf(['inline-block','block']),
    color: PropTypes.oneOf(Object.keys(COLORS)),
    disabled: PropTypes.bool,
    loadingText: PropTypes.string,
    /** Either return immediately or return a Promise for async actions **/
    onClick: PropTypes.func
};
Button.defaultProps = {
    size: 'normal',
    display: 'inline-block',
    color: 'primary',
    disabled: false,
    loadingText: 'Loading'
};

export default Button;
